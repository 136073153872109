import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo"
import CTA from "../components/cta";

import goldenGate from "../images/backrounds/golden-gate-filter.png";

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Technology Audits"
        description="Our comprehensive audits include deep code dives, reviews development process and evaluates best practices in testing, security, and scalability."
      />
      <section>
        <div
          className="hero"
          style={{
            backgroundImage: `url(${goldenGate})`,
          }}
        >
          <div className="hero-title bg-primary-transparent">
            <h1 className="text-white">tech&shy;nology audits</h1>
            <p className="text-white">
              729 Solutions offers comprehensive technology audit reports for brokers, valuers, agents, buyers and sellers that do deep code dives, review development process and evaluate best practices in testing, security, and scalability.
            </p>
            <CTA
              href="/contact-us/"
              text="REQUEST AN AUDIT"
              className="btn btn-secondary-light"
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row">
            <div className="col-lg-6">
              <h2>how does it work?</h2>
              <p>729 works as an independent third party to help the sale transact with certainty. We work with buyers, sellers and brokers to help place a meaningful value on any code used by the business. We can be engaged directly by the broker as a referral or white labelled.</p>
              <p>Reports typically take 2-4 weeks and can be done 100% remotely. 729 will provide a detailed report that helps all parties fairly value the deal.</p>
              <p>We work with buyer or seller to understand what they need from the report. We can evaluate risks, resource alignment, costs to modernize or integrate with new technologies. We can report on how the code is architected and look at database and server/hosting issues as needed.</p>
            </div>
            <div className="col-lg-6">
              <h2>our professional audits give you insights into:</h2>
              <ul className="text-primary">
                <li><span className="text-dark">Scalability risks and issues</span></li>
                <li><span className="text-dark">Server and operations evaluation</span></li>
                <li><span className="text-dark">Database architecture analysis</span></li>
                <li><span className="text-dark">Languages and platform challenges</span></li>
                <li><span className="text-dark">Integrations and third party software dependenc</span></li>
                <li><span className="text-dark">Project Management and iteration practices</span></li>
                <li><span className="text-dark">Development velocity and team competency</span></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-6">
          <div className="row ">
            <div className="col">
              <h2>frequently asked questions</h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="accordion-729">
                <div>
                  <input  checked="checked"  readOnly className="ac-input" id="ac-1" name="accordion-1" type="checkbox"/>
                  <label className="ac-label" htmlFor="ac-1">what happens if we find problems?<span></span></label>
                  <div className="article ac-content">
                    <p className="m-0">
                      It is not enough to know there are problems. The real value is in knowing how much it will cost to fix the issues in time and cost. If 729 finds anything that affects the investment we prepare a detailed analysis of the effort needed to do any repair work including number of resources, time to delivery and price. We offer the investor a granular report of all the upgrades, code changes, and refactoring to get the technology to where it should be.
                    </p>
                  </div>
                </div>
                <div>
                  <input className="ac-input" id="ac-2" name="accordion-1" type="checkbox"/>
                  <label className="ac-label" htmlFor="ac-2">how do we manage security and privacy?<span></span></label>
                  <div className="article ac-content">
                    <p className="m-0">729 Solutions acts as an independent third party. We operate under NDA with the seller so there is no risk to showing their code base to us. We do not reveal the contents of the code but rather do an impartial evaluation of its challenges and qualities. This offers a way for the buyer to have the most detailed look inside the program without any legal or professional risks.</p>
                  </div>
                </div>
                <div>
                  <input className="ac-input" id="ac-3" name="accordion-1" type="checkbox"/>
                  <label className="ac-label" htmlFor="ac-3">how long does it take?<span></span></label>
                  <div className="article ac-content">
                    <p className="m-0">Typically it takes 1-2 weeks to complete the consultation and contract phase with the buyer or seller. Reporting then takes between 2-4 weeks depending on the extent of the audit. If there are quicker deadlines we can expedite the report.</p>
                  </div>
                </div>
                <div>
                  <input className="ac-input" id="ac-4" name="accordion-1" type="checkbox"/>
                  <label className="ac-label" htmlFor="ac-4">how much does it cost?<span></span></label>
                  <div className="article ac-content">
                    <p className="m-0">We price the report depending on a number of factors including the size and complexity of the code base, database, languages and integrations as well as the depth of assessment areas we audit of the code base. Price ranges from $5,000 -$15,000 and you can align cost with depth to get the report you want for your customer’s budget.
                    </p>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
